

























































import useValidate from '@/use/validate'
import { defineComponent, onMounted, reactive, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'
import Joi from 'joi'

export default defineComponent({
  setup(_, { root }) {
    const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance
    const { tlds, validate } = useValidate()

    const form = ref<any>(null)
    const company = ref<any>(null)
    const companyId = ref<string>(root.$route.params.id)

    const state = reactive({
      loading: false,
      valid: true
    })

    const model = reactive({
      NIP: '',
      REGON: '',
      KRS: '',

      name: '',
      address: '',
      postcode: '',
      city: '',
      phone: '',
      email: ''
    })

    const rules: { [key: string]: Array<(v: any) => boolean | string> } = {
      NIP: [v => !v || /^[0-9]{3}-[0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(v) || 'Niepoprawny format'],
      REGON: [v => !v || /^[0-9]{9}$/.test(v) || 'Niepoprawny format'],
      KRS: [v => !v || /^[0-9]{10}$/.test(v) || 'Niepoprawny format'],

      name: [v => !!v || 'Podaj nazwę firmy'],
      address: [v => !!v || 'Podaj ulicę i numer'],
      postcode: [v => !!v || 'Podaj kod pocztowy'],
      city: [v => !!v || 'Podaj miasto'],
      phone: [v => !!v || 'Podaj telefon'],
      email: [
        v => !!v || 'Podaj adres email',
        v => !!validate(v, Joi.string().email({ tlds: { allow: tlds.map(d => d.toLowerCase()) } })) || 'Podaj prawidłowy adres email'
      ]
    }

    const updateModel = () => {
      model.NIP = company.value?.NIP
      model.REGON = company.value?.Regon
      model.KRS = company.value?.KRS

      model.name = company.value?.name
      model.address = company.value?.address
      model.postcode = company.value?.postcode
      model.city = company.value?.city
      model.phone = company.value?.phone
      model.email = company.value?.email
    }

    watch(() => company.value, updateModel, { deep: true })

    const fetchCompany = () => {
      state.loading = true

      axiosInstance
        .get(`company/${companyId.value}`)
        .then(({ data }) => company.value = data)
        .catch(console.log)
        .finally(() => state.loading = false)
    }

    onMounted(fetchCompany)

    const updateCompany = () => {
      state.loading = true

      const data = {
        NIP: model.NIP || null,
        Regon: model.REGON || null,
        KRS: model.KRS || null,

        name: model.name,
        address: model.address,
        postcode: model.postcode,
        city: model.city,
        phone: model.phone,
        email: model.email
      }

      axiosInstance
        .put(`company/${companyId.value}`, data)
        .then(() => {
          root.$router.push({ name: 'panel.company.list' })
        })
        .catch(console.log)
        .finally(() => state.loading = false)
    }

    const onSubmit = () => {
      form.value.validate()
      if (state.valid) updateCompany()
    }

    return { form, company, state, model, rules, onSubmit }
  }
})
